// @ts-nocheck
import Script from 'next/script'
import { tealiumData } from '@/modules/tealium/Tealium.model'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'
import { useRouter } from 'next/router'
import { getCookies, cleanUp } from '@/utils/cookies/cookies'
import * as SupportPageUtility from '@/modules/tealium/utilities/pages/support'

export const jQueryScriptId = 'jquery-script'

export const uTagsScriptId = 'utags-script'

export const devScript =
  'https://tags.tiqcdn.com/utag/usbank/developer-portal/dev/utag.js'

export const prodScript =
  'https://tags.tiqcdn.com/utag/usbank/developer-portal/prod/utag.js'

const SUPPORT_PAGE_PATHS = [
    'mtls-guide',
    SupportPageUtility.CREATE_PATH,
    'user-guide',
    'getting-started-with-our-apis',
    'faq',
    'contact',
  ],
  API_REFERENCE_DOWNLOAD_LINK_SELECTOR = 'a[download="openapi.json"]',
  DEFAULT_PUBLISH_EVENT = 'pageView',
  LOADING_STATUS = 'loading',
  AUTHENTICATED_USER_STATUS = 'authenticated',
  DEFAULT_GET_SUB_SITE_SECTION = 'other',
  COMPANY_NAME_UNAVAILABLE = 'company unavailable - user logged out',
  HOME_SITE_SECTION = 'home',
  CURRENT_PAGE_SUPPORT_KEY = 'support',
  CURRENT_PAGE_ADD_TO_PROJECT_KEY = 'add to project click',
  CURRENT_PAGE_ADD_USER_KEY = 'add click',
  CURRENT_PAGE_REMOVE_USER_KEY = 'remove user click',
  CURRENT_PAGE_DENY_USER_KEY = 'deny user click',
  CURRENT_PAGE_SAVE_PROFILE_KEY = 'save profile click',
  CURRENT_PAGE_VERIFY_ORG_KEY = 'verify organization click',
  CURRENT_PAGE_EMAIL_MATCH_VERIFY_ORG_KEY = 'email match verify organization click',
  CURRENT_PAGE_REQUEST_ACCESS_KEY = 'request access click',
  CURRENT_PAGE_CREATE_SANDBOX_KEY = 'form start click',
  CURRENT_PAGE_SAVE_SANDBOX_PROJECT_KEY = 'form submit click',
  CURRENT_PAGE_CONFIRM_CERTIFICATE_KEY = 'confirm installation:confirm click',
  CURRENT_PAGE_MTLS_UPLOAD_KEY = 'upload certifiate:upload click',
  CURRENT_PAGE_DOWNLOAD_KEY = 'download click',
  DASHBOARD_PAGE_NON_VERIFIED_ORG = 'non verified org',
  ERROR_PAGE_KEY = 'error',
  ERROR_MESSAGE_400 = '400 page requested',
  ERROR_MESSAGE_401 = '401 page requested',
  ERROR_MESSAGE_404 = '404 page requested',
  ERROR_MESSAGE_405 = '405 page requested',
  ERROR_MESSAGE_500 = '500 page requested',
  FILE_DOWNLOAD_TRANSACTION_STATUS = 'file download',
  FORM_FAILURE_TRANSACTION_STATUS = 'form error',
  FORM_INTERACTION_MSG_START = 'form start',
  FORM_INTERACTION_MSG_SUBMIT = 'form submit',
  SIGN_UP_FORM_SUBMIT = 'form submit click',
  SAVE_SANDBOX_PROJECT_MODAL_SUBMIT = 'form submit',
  SANDBOX_CREATE_PROJECT_KEY = 'sandbox project',
  //CONFIRM_CERT_INSTALLATION_KEY = 'api req details',
  PROD_ENV = 'prod',
  CLICK_PUBLISHING_EVENT = 'onClick',
  REPORT_SCRIPT_CHECK_LIMIT = 20,
  CHARACTERS_TO_REPLACE = ['-', ':', '_'],
  CONTACT_PAGE_PATH = '/contact',
  CONTACT_FORM_ELEMENTS = [
    '#input_fullName',
    '#input_email',
    '#input_organization',
    '#input_phoneNumber',
    '.usb--radio-input',
    '#input_message',
    '#select_supportType',
  ],
  SIGN_UP_PAGE_PATH = '/sign-up',
  SIGN_UP_FORM_ELEMENTS = [
    '#input_firstname',
    '#input_lastname',
    '#input_email',
    '#input_confirmemail',
    '#input_organization',
  ],
  CREATE_SIGN_UP_FORM_WITH_PERSONAL_EMAIL_KEY = 'create with personal id submit click',
  CREATE_SIGN_UP_FORM_WITHOUT_PERSONAL_EMAIL_KEY = 'cancel personal id submit click',
  LOGIN_PAGE_PATH = '/login',
  LOGIN_FORM_ELEMENTS = ['#input_username', '#input_password-input'],
  SEARCH_PAGE_PATH = '/search'

export let publishEvent: string = DEFAULT_PUBLISH_EVENT,
  supportPage: boolean = false
let router: any,
  status: string = LOADING_STATUS,
  errorPage400: boolean = false,
  errorPage401: boolean = false,
  errorPage404: boolean = false,
  errorPage405: boolean = false,
  errorPage500: boolean = false,
  editPage: boolean = false,
  addPage: boolean = false,
  mtlsUploadPage: boolean = false,
  apiDetailsPage: boolean = false,
  reportingData: {},
  analyticsReported: boolean = false,
  contactFormTouched: boolean = false,
  signUpFormTouched: boolean = false,
  loginFormTouched: boolean = false,
  authenticationStatus: 'authenticated' | 'unauthenticated',
  userInfo: {},
  utagScript: string,
  addToProjectClicked: boolean = false,
  addUserClicked: boolean = false,
  removeUserClicked: boolean = false,
  assignUserClicked: boolean = false,
  denyUserClicked: boolean = false,
  saveProfileClicked: boolean = false,
  verifyOrgModalClicked: boolean = false,
  emailMatchVerifyOrgModalClicked: boolean = false,
  createSandboxProjectClicked: boolean = false,
  confirmCertInstallationClicked: boolean = false,
  mtlsFileUploadClicked: boolean = false,
  saveSandboxProjectClicked: boolean = false,
  sandboxProjectFormValidationFailure: boolean = false,
  contactFormValidationFailure: boolean = false,
  signUpFormValidationFailure: boolean = false,
  loginFormValidationFailure: boolean = false,
  contactFormSuccessfullyDelivered: boolean = false,
  signUpFormSuccessfullyDelivered: boolean = false,
  createSignUpFormWithoutPersonalEmailClicked: boolean = false,
  loginFormSuccessfullyDelivered: boolean = false,
  featureCategoryClicked: boolean = false,
  featureCategoryValue: string = '',
  assignUserText: string = '',
  checkMainContactOption: boolean = false,
  createWithPersonalEmailOption: boolean = false,
  supportTypeValue: string = '',
  intervalArr = [],
  callReportScriptCount: number = 0,
  markdownElementClicked: boolean = false,
  displayLoginModalClicked: boolean = false,
  submitLoginFormClicked: boolean = false,
  cookieSettingsClicked: boolean = false,
  cookiesValue = [],
  searchFormFilterClicked: boolean = false

function focusEventHandler() {
  publishEvent = CLICK_PUBLISHING_EVENT
  analyticsReported = false

  // remove all focus event handlers after focus event triggered
  if (router.asPath === CONTACT_PAGE_PATH) {
    contactFormTouched = true

    CONTACT_FORM_ELEMENTS.forEach((element) => {
      const elements = document.querySelectorAll(element)
      elements.forEach((element) => {
        element.removeEventListener('focus', focusEventHandler)
      })
    })
  }

  if (router.asPath === SIGN_UP_PAGE_PATH) {
    signUpFormTouched = true

    SIGN_UP_FORM_ELEMENTS.forEach((element) => {
      const elements = document.querySelectorAll(element)
      elements.forEach((element) => {
        element.removeEventListener('focus', focusEventHandler)
      })
    })
  }

  if (router.asPath === LOGIN_PAGE_PATH) {
    loginFormTouched = true

    LOGIN_FORM_ELEMENTS.forEach((element) => {
      const elements = document.querySelectorAll(element)
      elements.forEach((element) => {
        element.removeEventListener('focus', focusEventHandler)
      })
    })
  }

  return generateAndReportData()
}

function removeHash(text: string) {
  let hashIndex = text.indexOf('#')

  if (hashIndex !== -1) {
    return text.substring(0, hashIndex)
  }

  return text
}

function attachContactFormEventHandlers() {
  if (router.asPath !== CONTACT_PAGE_PATH) {
    return false
  }

  CONTACT_FORM_ELEMENTS.forEach((contactFormElement) => {
    if (document.querySelector(contactFormElement)) {
      const elements = document.querySelectorAll(contactFormElement)

      elements.forEach((element) => {
        element.addEventListener('focus', focusEventHandler)
      })
    }
  })
}

function attachSignUpFormEventHandlers() {
  if (router.asPath !== SIGN_UP_PAGE_PATH) {
    return false
  }

  SIGN_UP_FORM_ELEMENTS.forEach((signUpFormElement) => {
    if (document.querySelector(signUpFormElement)) {
      const elements = document.querySelectorAll(signUpFormElement)

      elements.forEach((element) => {
        element.addEventListener('focus', focusEventHandler)
      })
    }
  })
}

function attachLoginFormEventHandlers() {
  if (router.asPath !== LOGIN_PAGE_PATH) {
    return false
  }

  LOGIN_FORM_ELEMENTS.forEach((loginFormElement) => {
    if (document.querySelector(loginFormElement)) {
      const elements = document.querySelectorAll(loginFormElement)

      elements.forEach((element) => {
        element.addEventListener('focus', focusEventHandler)
      })
    }
  })
}

function attachMarkdownContentEventHandlers() {
  const apiReferenceDownloadLinks = document.querySelectorAll(
    API_REFERENCE_DOWNLOAD_LINK_SELECTOR
  )

  apiReferenceDownloadLinks.forEach((element) => {
    element.addEventListener('click', markdownLinkCallback)
  })
}

function resetUserActivity() {
  markdownElementClicked = false
  addToProjectClicked = false
  addUserClicked = false
  removeUserClicked = false
  assignUserClicked = false
  denyUserClicked = false
  saveProfileClicked = false
  verifyOrgModalClicked = false
  emailMatchVerifyOrgModalClicked = false
  createSandboxProjectClicked = false
  confirmCertInstallationClicked = false
  mtlsFileUploadClicked = false
  saveSandboxProjectClicked = false
  sandboxProjectFormValidationFailure = false
  featureCategoryClicked = false
  featureCategoryValue = ''
  assignUserText = ''
  checkMainContactOption = false
  createWithPersonalEmailOption = false
  supportTypeValue = ''
  contactFormValidationFailure = false
  signUpFormValidationFailure = false
  loginFormValidationFailure = false
  contactFormSuccessfullyDelivered = false
  signUpFormSuccessfullyDelivered = false
  createSignUpFormWithoutPersonalEmailClicked = false
  loginFormSuccessfullyDelivered = false
  contactFormTouched = false
  signUpFormTouched = false
  loginFormTouched = false
  errorPage400 = false
  errorPage401 = false
  errorPage404 = false
  errorPage405 = false
  errorPage500 = false
  editPage = false
  addPage = false
  mtlsUploadPage = false
  apiDetailsPage = false
  publishEvent = DEFAULT_PUBLISH_EVENT
  callReportScriptCount = 0
  displayLoginModalClicked = false
  submitLoginFormClicked = false
  cookieSettingsClicked = false
  cookiesValue = []
  searchFormFilterClicked = false
}

export function reportDataClickEvent(data) {
  window.publisherFW.publishEvent(CLICK_PUBLISHING_EVENT, data)
  analyticsReported = true
  resetUserActivity()

  return <></>
}

function callReportScript() {
  intervalArr.push(setInterval(callReportScript, 1000))
  callReportScriptCount++

  if (callReportScriptCount > REPORT_SCRIPT_CHECK_LIMIT) {
    intervalArr.forEach((intervalId) => {
      clearInterval(intervalId)
    })
  }

  if (window.publisherFW) {
    intervalArr.forEach((intervalId) => {
      clearInterval(intervalId)
    })

    window.publisherFW.publishEvent(publishEvent, JSON.parse(reportingData))
    analyticsReported = true
    resetUserActivity()

    SupportPageUtility.clickAutoSelectedRadioBtns(router.asPath)

    return <></>
  }

  return <></>
}

function generateAndReportData(routerParam?) {
  if (!router && !routerParam) {
    router = useRouter()
  } else if (!router && routerParam) {
    router = routerParam
  }

  generateReportingData()

  return callReportScript()
}

export function generateReportingData() {
  authenticationStatus = 'unauthenticated'

  if (status === AUTHENTICATED_USER_STATUS) {
    authenticationStatus = 'authenticated'
  }

  reportingData = getReportingData()

  return JSON.parse(reportingData)
}
function getCompanyName() {
  if (status === AUTHENTICATED_USER_STATUS) {
    if (userInfo?.registeredOrganization === '') {
      return COMPANY_NAME_UNAVAILABLE
    } else if (userInfo?.registeredOrganization !== '' && userInfo?.organization === null) {
      return DASHBOARD_PAGE_NON_VERIFIED_ORG
    } else {
      return userInfo?.organization?.businessName
    }
  }

  return COMPANY_NAME_UNAVAILABLE
}


function getPath() {
  let path = router.asPath,
    queryParameterIndex = path.indexOf('?')

  // remove query parameters
  if (queryParameterIndex !== -1) {
    path = router.asPath.substr(0, queryParameterIndex)
  }

  return path
}

function replaceCharacters(text: string) {
  CHARACTERS_TO_REPLACE.forEach((character) => {
    text = text.replaceAll(character, ' ')
  })

  return text
}

function getCurrentPage() {
  let currentPage = '',
    path = getPath(),
    pathArr: [] = path.split('/')

  currentPage = `${process.env.ANALYTICS_CURRENT_PAGE}:${getSiteSection()}`

  if (router.asPath === '/' && featureCategoryClicked) {
    return currentPage + ':' + featureCategoryValue + ' click'
  }
  if (verifyOrgModalClicked) {
    let pageVal =
      currentPage +
      `:${CURRENT_PAGE_VERIFY_ORG_KEY}`
    return removeHash(pageVal)
  }

  if (emailMatchVerifyOrgModalClicked) {
    let pageVal =
      currentPage +
      `:${CURRENT_PAGE_EMAIL_MATCH_VERIFY_ORG_KEY}`
    return removeHash(pageVal)
  }

  if (createSandboxProjectClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${CURRENT_PAGE_CREATE_SANDBOX_KEY}`
    return removeHash(pageVal)
  }

  if (confirmCertInstallationClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${CURRENT_PAGE_CONFIRM_CERTIFICATE_KEY}`
    return removeHash(pageVal)
  }

  if(mtlsFileUploadClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${CURRENT_PAGE_MTLS_UPLOAD_KEY}`
    return removeHash(pageVal)
  }

  if (saveSandboxProjectClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${CURRENT_PAGE_SAVE_SANDBOX_PROJECT_KEY}`
    return removeHash(pageVal)
  }

  if (addUserClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${CURRENT_PAGE_ADD_USER_KEY}`
    return removeHash(pageVal)
  }

  if (removeUserClicked) {
    let pageVal =
      currentPage +
      `:${CURRENT_PAGE_REMOVE_USER_KEY}`
    return removeHash(pageVal)
  }

  if (assignUserClicked) {
    let pageVal =
      currentPage +
      `:${assignUserText} user click`
    return removeHash(pageVal)
  }

  if (denyUserClicked) {
    let pageVal =
      currentPage +
      `:${CURRENT_PAGE_DENY_USER_KEY}`
    return removeHash(pageVal)
  }
  if (saveProfileClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${CURRENT_PAGE_SAVE_PROFILE_KEY}`
    return removeHash(pageVal)
  }

  if (displayLoginModalClicked) {
    let pageVal =
      currentPage +
      `:${getSubSiteSection()}:${FORM_INTERACTION_MSG_START} click`
    return removeHash(pageVal)
  }


  if (createSignUpFormWithoutPersonalEmailClicked) {
    let pageVal = currentPage + `:${CREATE_SIGN_UP_FORM_WITHOUT_PERSONAL_EMAIL_KEY}`
    return removeHash(pageVal)
  }


  if (signUpFormTouched) {
    let pageVal = currentPage + `:${FORM_INTERACTION_MSG_START} click`
    return removeHash(pageVal)
  }

  if (loginFormTouched) {
    let pageVal = currentPage + `:${FORM_INTERACTION_MSG_START} click`
    return removeHash(pageVal)
  }


  if (signUpFormSuccessfullyDelivered) {
    let pageVal = currentPage + `:${SIGN_UP_FORM_SUBMIT}`
    return removeHash(pageVal)
  }

  if (loginFormSuccessfullyDelivered && router.asPath === LOGIN_PAGE_PATH) {
    let pageVal = currentPage + `:${FORM_INTERACTION_MSG_SUBMIT} click`
    return removeHash(pageVal)
  } else {
    if (submitLoginFormClicked) {
      let pageVal =
        currentPage +
        `:${getSubSiteSection()}:${FORM_INTERACTION_MSG_SUBMIT} click`
      return removeHash(pageVal)
    }
  }

  if (signUpFormValidationFailure) {
    let pageVal = currentPage + `:${FORM_FAILURE_TRANSACTION_STATUS}`
    return removeHash(pageVal)
  }

  if (sandboxProjectFormValidationFailure) {
    let pageVal = currentPage + `:${getSubSiteSection()}:${FORM_FAILURE_TRANSACTION_STATUS}`
    return removeHash(pageVal)
  }

  if (loginFormValidationFailure && router.asPath === LOGIN_PAGE_PATH) {
    let pageVal = currentPage + `:${FORM_FAILURE_TRANSACTION_STATUS}`
    return removeHash(pageVal)
  } else {
    if (loginFormValidationFailure) {
      let pageVal =
        currentPage +
        `:${getSubSiteSection()}:${FORM_FAILURE_TRANSACTION_STATUS}`
      return removeHash(pageVal)
    }
  }

  if (router.asPath === '/') {
    return currentPage
  }

  // 400/401/404/405/500 page
  if (
    errorPage400 ||
    errorPage401 ||
    errorPage404 ||
    errorPage405 ||
    errorPage500
  ) {
    return `${process.env.ANALYTICS_CURRENT_PAGE}:${ERROR_PAGE_KEY}`
  }

  if (searchFormFilterClicked) {
    return currentPage + ':filter click'
  }

  // check for support page and forgot password/username pages
  for (let index = 0; index < SUPPORT_PAGE_PATHS.length; index++) {
    const element = SUPPORT_PAGE_PATHS[index]

    if (pathArr.includes(element) || router.asPath === element) {
      if (contactFormValidationFailure) {
        let pageVal = `${process.env.ANALYTICS_CURRENT_PAGE
          }:${CURRENT_PAGE_SUPPORT_KEY}:${replaceCharacters(
            element
          )}:${FORM_FAILURE_TRANSACTION_STATUS}`

        return removeHash(pageVal)
      }

      if (contactFormSuccessfullyDelivered) {
        let pageVal = `${process.env.ANALYTICS_CURRENT_PAGE
          }:${CURRENT_PAGE_SUPPORT_KEY}:${replaceCharacters(
            element
          )}:${FORM_INTERACTION_MSG_SUBMIT} click`

        return removeHash(pageVal)
      }

      if (contactFormTouched) {
        let pageVal = `${process.env.ANALYTICS_CURRENT_PAGE
          }:${CURRENT_PAGE_SUPPORT_KEY}:${replaceCharacters(
            element
          )}:${FORM_INTERACTION_MSG_START} click`

        return removeHash(pageVal)
      }

      let pageVal = `${process.env.ANALYTICS_CURRENT_PAGE
        }:${CURRENT_PAGE_SUPPORT_KEY}:${replaceCharacters(element)}`

      return removeHash(pageVal)
    }
  }

  // paths for solution area overview and product overviews
  if (pathArr.length === 3 || pathArr.length === 4) {
    if (addToProjectClicked) {
      let pageVal =
        currentPage +
        `:${getSubSiteSection()}:${CURRENT_PAGE_ADD_TO_PROJECT_KEY}`

      return removeHash(pageVal)
    }

    let pageVal = `${currentPage}:${getSubSiteSection()}`

    if (pageVal.slice(-1) === ':') {
      return pageVal.substring(0, pageVal.length - 1)
    }

    return pageVal
  }

  // paths for dashboard
  if (pathArr.length === 1) {
    let pageVal = `${currentPage}:${getSubSiteSection()}`

    if (pageVal.slice(-1) === ':') {
      return pageVal.substring(0, pageVal.length - 1)
    }

    return pageVal
  }

  // paths for product documentation
  if (pathArr.length >= 5) {
    if (markdownElementClicked) {
      let pageVal =
        currentPage +
        `:${getSubSiteSection()}:${replaceCharacters(
          pathArr[4]
        )}:${CURRENT_PAGE_DOWNLOAD_KEY}`

        return removeHash(pageVal)
    }

    if (addToProjectClicked) {
      let pageVal =
        currentPage +
        `:${getSubSiteSection()}:${pathArr[4]
        }:${CURRENT_PAGE_ADD_TO_PROJECT_KEY}`

      return removeHash(pageVal)
    }

    let pageVal = currentPage + `:${getSubSiteSection()}:${getPage()}`

    return removeHash(pageVal)
  }

  return currentPage
}

function getPage() {
  let pathArray = router.asPath.split('/'),
    pathValue: string

  // path for product and product documentation
  if (pathArray.length === 5) {
    // path for product and product documentation (no locale in path)
    return removeHash(replaceCharacters(pathArray[4]))
  } else if (pathArray.length > 5) {
    // path for product and product documentation (locale in path)
    return removeHash(replaceCharacters(pathArray[5]))
  }

  return ''
}

export function resetReporting() {
  analyticsReported = false
}

function getReportingDataEvent (reportingData) {
  switch (publishEvent) {
    case DEFAULT_PUBLISH_EVENT:
      reportingData.currentPage = getCurrentPage()
      break
    default:
      reportingData.onClickEvent = getCurrentPage()
      break
  }
}

function getReportingDataErrorPageMessage(reportingData) {
  if (errorPage400) {
    reportingData.errorMessage = ERROR_MESSAGE_400
  }

  if (errorPage401) {
    reportingData.errorMessage = ERROR_MESSAGE_401
  }

  if (errorPage404) {
    reportingData.errorMessage = ERROR_MESSAGE_404
  }

  if (errorPage405) {
    reportingData.errorMessage = ERROR_MESSAGE_405
  }

  if (errorPage500) {
    reportingData.errorMessage = ERROR_MESSAGE_500
  }
}

function getReportingDataFromSearchPage(reportingData) {
  if (router.asPath === SEARCH_PAGE_PATH) {
    reportingData.searchTerm = ''
  }

  if (router.route === SEARCH_PAGE_PATH && router.query.productId) {
    reportingData.filter = router.query.productId.replaceAll('-', '')
  }

  if (router.route === SEARCH_PAGE_PATH && router.query.searchTermQuery) {
    reportingData.searchTerm = router.query.searchTermQuery
  }
}

function getReportingDataFromLoginForm(reportingData) {
  if (displayLoginModalClicked) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
  }

  if (loginFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS
  }

  if (loginFormTouched) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
  }

  if (loginFormSuccessfullyDelivered) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
  }

  if (submitLoginFormClicked) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
  }
}

function getReportingDataFromSignUpForm(reportingData) {
  if (signUpFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS
  }

  if (signUpFormTouched) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
  }

  if (signUpFormSuccessfullyDelivered) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
  }
}

function getReportingDataFromContactForm(reportingData) {
  if (contactFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS

    if (process.env.SITE_BRAND === 'elavon') {
      reportingData.supportType = supportTypeValue
    }
  }

  if (contactFormTouched) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
    if (process.env.SITE_BRAND === 'elavon') {
      reportingData.supportType = supportTypeValue
    }
  }

  if (contactFormSuccessfullyDelivered) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
    if (process.env.SITE_BRAND === 'elavon') {
      reportingData.supportType = supportTypeValue
    }
  }
}

function getReportingDataFromMarkdownElement(reportingData) {
  if (markdownElementClicked) {
    reportingData.transactionStatus = FILE_DOWNLOAD_TRANSACTION_STATUS
  }
}

function getReportingDataFromCookieSettingsPage(reportingData) {
  if (cookieSettingsClicked) {
    reportingData.cookies = cookiesValue
  }
}

function getReportingData() {
  let reportingData = {
    pageUrl: getUrl(),
    siteSection: getSiteSection(),
    subSiteSection: getSubSiteSection(),
    version: getVersion(),
    companyName: getCompanyName(),
    sitePlatform: process.env.ANALYTICS_PLATFORM,
    userAuthenticated: authenticationStatus,
    siteLocale: getSiteLocale(),
  }

  switch (publishEvent) {
    case DEFAULT_PUBLISH_EVENT:
      reportingData.currentPage = getCurrentPage()
      break
    default:
      reportingData.onClickEvent = getCurrentPage()
      break
  }

  if (contactFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS
    if (process.env.SITE_BRAND === 'elavon') {
      reportingData.supportType = supportTypeValue
    }
  }

  if (signUpFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS
    reportingData.formName = getSiteSection()
  }

  if (sandboxProjectFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS
    reportingData.formName = SANDBOX_CREATE_PROJECT_KEY
  }

  if (loginFormValidationFailure) {
    reportingData.transactionStatus = FORM_FAILURE_TRANSACTION_STATUS
    reportingData.formName = getSubSiteSection()
  }
  if (createSandboxProjectClicked) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
    reportingData.formName = SANDBOX_CREATE_PROJECT_KEY
  }
  if (saveSandboxProjectClicked) {
    reportingData.transactionStatus = SAVE_SANDBOX_PROJECT_MODAL_SUBMIT
    reportingData.formName = SANDBOX_CREATE_PROJECT_KEY
  }

  if (contactFormTouched) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
    if (process.env.SITE_BRAND === 'elavon') {
      reportingData.supportType = supportTypeValue
    }
  }

  if (signUpFormTouched) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
    reportingData.formName = getSiteSection()
  }

  if (loginFormTouched) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
    reportingData.formName = getSubSiteSection()
  }

  if (contactFormSuccessfullyDelivered) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
    if (process.env.SITE_BRAND === 'elavon') {
      reportingData.supportType = supportTypeValue
    }
  }

  if (signUpFormSuccessfullyDelivered) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
    reportingData.formName = getSiteSection()
    if(createWithPersonalEmailOption == true){
      reportingData.createWithPersonalEmail = "1"
    }
  }

  if (loginFormSuccessfullyDelivered) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
    reportingData.formName = getSubSiteSection()
  }

  

  if (displayLoginModalClicked) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_START
    reportingData.formName = getSubSiteSection()
  }

  if (submitLoginFormClicked) {
    reportingData.transactionStatus = FORM_INTERACTION_MSG_SUBMIT
    reportingData.formName = getSubSiteSection()
  }

  if (markdownElementClicked) {
    reportingData.transactionStatus = FILE_DOWNLOAD_TRANSACTION_STATUS
  }

  if (saveProfileClicked) {
    if (checkMainContactOption == false) {
      reportingData.transactionStatus = "no change"
    } else {
      reportingData.transactionStatus = "new main contact"
    }
  }

  if (errorPage400) {
    reportingData.errorMessage = ERROR_MESSAGE_400
  }

  if (errorPage401) {
    reportingData.errorMessage = ERROR_MESSAGE_401
  }

  if (errorPage404) {
    reportingData.errorMessage = ERROR_MESSAGE_404
  }

  if (errorPage405) {
    reportingData.errorMessage = ERROR_MESSAGE_405
  }

  if (errorPage500) {
    reportingData.errorMessage = ERROR_MESSAGE_500
  }

  if (cookieSettingsClicked) {
    reportingData.cookies = cookiesValue
  }

  if (supportPage) {
    SupportPageUtility.attachClickHandlers()
  }

  if (process.env.SITE_BRAND === 'elavon') {
    if (router.asPath === SEARCH_PAGE_PATH) {
      reportingData.searchTerm = ''
    }

    if (router.route === SEARCH_PAGE_PATH && router.query.productId) {
      reportingData.filter = router.query.productId.replaceAll('-', '')
    }

    if (router.route === SEARCH_PAGE_PATH && router.query.searchTermQuery) {
      reportingData.searchTerm = router.query.searchTermQuery
    }
  }

  if (errorPage400) {
    reportingData.errorMessage = ERROR_MESSAGE_400
  }

  if (errorPage401) {
    reportingData.errorMessage = ERROR_MESSAGE_401
  }

  if (errorPage404) {
    reportingData.errorMessage = ERROR_MESSAGE_404
  }

  if (errorPage405) {
    reportingData.errorMessage = ERROR_MESSAGE_405
  }

  if (errorPage500) {
    reportingData.errorMessage = ERROR_MESSAGE_500
  }

  if (cookieSettingsClicked) {
    reportingData.cookies = cookiesValue
  }

  if (process.env.SITE_BRAND === 'elavon') {
    if (router.asPath === SEARCH_PAGE_PATH) {
      reportingData.searchTerm = ''
    }

    if (router.route === SEARCH_PAGE_PATH && router.query.productId) {
      reportingData.filter = router.query.productId.replaceAll('-', '')
    }

    if (router.route === SEARCH_PAGE_PATH && router.query.searchTermQuery) {
      reportingData.searchTerm = router.query.searchTermQuery
    }
  }
  return JSON.stringify(reportingData)
}

function getSiteSection() {
  const pathArray = getPath().split('/')

  if (pathArray[0] === '' && pathArray[1] === '') {
    return HOME_SITE_SECTION
  }

  // 400/401/404/405/500 page
  if (
    errorPage400 ||
    errorPage401 ||
    errorPage404 ||
    errorPage405 ||
    errorPage500
  ) {
    return ERROR_PAGE_KEY
  }

  // check for support page
  for (let index = 0; index < SUPPORT_PAGE_PATHS.length; index++) {
    const element = SUPPORT_PAGE_PATHS[index]

    if (pathArray.includes(element) || router.asPath.includes(element)) {
      return CURRENT_PAGE_SUPPORT_KEY
    }
  }

  if (pathArray[1]) {
    let siteSection: string

    if (pathArray[1] === '') {
      return HOME_SITE_SECTION
    }

    siteSection = replaceCharacters(pathArray[1])

    return removeHash(siteSection)
  }

  return ''
}

function getSubSiteSection() {
  let pathArray = router.asPath.split('/')

  // 400/401/404/405/500 page
  if (
    errorPage400 ||
    errorPage401 ||
    errorPage404 ||
    errorPage405 ||
    errorPage500
  ) {
    return ''
  }

  if (
    displayLoginModalClicked ||
    submitLoginFormClicked ||
    loginFormValidationFailure ||
    loginFormSuccessfullyDelivered
  ) {
    return 'login'
  }

  if (
    createSandboxProjectClicked ||
    saveSandboxProjectClicked ||
    sandboxProjectFormValidationFailure
  ) {
    return 'create sandbox project'
  }

  if(confirmCertInstallationClicked || mtlsFileUploadClicked) {
    return 'api request details'
  }


  // remove token, if it's the reset page
  if (router.asPath.includes('/token:')) {
    return ''
  }

  // check for support page
  for (let index = 0; index < SUPPORT_PAGE_PATHS.length; index++) {
    const element = SUPPORT_PAGE_PATHS[index]

    if (supportPage) {
      return 'create'
    }

    if (pathArray.includes(element)) {
      return removeHash(replaceCharacters(element))
    }
  }

  // path for home and solution areas
  if (pathArray.length === 2) {
    return ''
  }

  // path for product and product documentation
  if (pathArray.length === 5) {
    // path for product and product documentation (no locale in path)
    return removeHash(replaceCharacters(pathArray[2]))
  } else if (pathArray.length > 5) {
    // path for product and product documentation (locale in path)
    return removeHash(replaceCharacters(pathArray[3]))
  }

  return DEFAULT_GET_SUB_SITE_SECTION
}

function getUrl() {
  if (!window?.location?.origin) {
    return 'url unavailable'
  }

  if (router.pathname === '/') {
    return `${window.location.origin}`
  }

  return `${window.location.origin}${router.asPath}`
}

function getVersion() {
  let path = router.asPath.split('/')

  // 400/401/404/405/500 page
  if (
    errorPage400 ||
    errorPage401 ||
    errorPage404 ||
    errorPage405 ||
    errorPage500 ||
    editPage ||
    addPage ||
    mtlsUploadPage ||
    apiDetailsPage ||
    saveProfileClicked
  ) {
    return ''
  }

  // path for product and product documentation
  if (path.length === 5) {
    // path for product and product documentation (no locale in path)
    return path[3]
  } else if (path.length > 5) {
    // path for product and product documentation (locale in path)
    return path[4]
  }

  return ''
}

function getSiteLocale() {
  let cookies = getCookies()
  if(cookies)
    return cookies.locale
  return ''
}

function markdownLinkCallback() {
  markdownElementClicked = true
  analyticsReported = false
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function getUtagScript() {
  return utagScript
}

export function report400(params, error400Router) {
  resetUserActivity()
  analyticsReported = false
  errorPage400 = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)

  return generateAndReportData(error400Router)
}

export function report401(params, error401Router) {
  resetUserActivity()
  analyticsReported = false
  errorPage401 = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)

  return generateAndReportData(error401Router)
}

export function report404(params, error404Router) {
  resetUserActivity()
  analyticsReported = false
  errorPage404 = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)

  return generateAndReportData(error404Router)
}

export function report405(params, error405Router) {
  resetUserActivity()
  analyticsReported = false
  errorPage405 = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)

  return generateAndReportData(error405Router)
}

export function report500(params, error500Router) {
  resetUserActivity()
  analyticsReported = false
  errorPage500 = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)

  return generateAndReportData(error500Router)
}

export function editPageAnalytics(params, editPageRouter) {
  resetUserActivity()
  analyticsReported = false
  editPage = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)
  return generateAndReportData(editPageRouter)
}

export function addPageAnalytics(params, addPageRouter) {
  resetUserActivity()
  analyticsReported = false
  addPage = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)
  return generateAndReportData(addPageRouter)
}

export function mtlsUploadPageAnalytics(params, mtlsUploadPageRouter) {
  resetUserActivity()
  analyticsReported = false
  mtlsUploadPage = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)
  return generateAndReportData(mtlsUploadPageRouter)
}

export function apiDetailsPageAnalytics(params, apiDetailsPageRouter) {
  resetUserActivity()
  analyticsReported = false
  apiDetailsPage = true
  status = params.status
  userInfo = params.userInfo

  getCdnScripts(params)
  return generateAndReportData(apiDetailsPageRouter)
}

export function addToProjectClickHandler() {
  addToProjectClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function addUserHandler() {
  addUserClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function verifyOrgModalClickHandler() {
  verifyOrgModalClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function emailMatchVerifyOrgModalClickHandler() {
  emailMatchVerifyOrgModalClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function createSandboxProjectHandler() {
  createSandboxProjectClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function confirmCertInstallationHandler() {
  confirmCertInstallationClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function mtlsFileUploadHandler() {
  mtlsFileUploadClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function removeUserHandler() {
  removeUserClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function saveSandboxProjectHandler() {
  saveSandboxProjectClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function assignUserHandler(assignType?) {
  assignUserClicked = true
  assignUserText = assignType.toLowerCase()
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function denyUserHandler() {
  denyUserClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function saveProfileHandler(userMainContact?) {

  saveProfileClicked = true
  checkMainContactOption = userMainContact
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()

}

export function createSignUpFormWithoUTPersonalEmailHandler() {
  createSignUpFormWithoutPersonalEmailClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT
  return generateAndReportData()
}

export function contactFormSuccessfulDelivery(supportType?) {
  contactFormSuccessfullyDelivered = true
  if (supportType) {
    supportTypeValue = supportType
  }
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function signUpFormSuccessfulDelivery(createWithPersonalEmail?) {
  signUpFormSuccessfullyDelivered = true
  createWithPersonalEmailOption = createWithPersonalEmail
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function loginFormSuccessfulDelivery() {
  loginFormSuccessfullyDelivered = true
  submitLoginFormClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function contactFormValidationFail(supportType?) {
  contactFormValidationFailure = true
  if (supportType) {
    supportTypeValue = supportType
  }
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function signUpFormValidationFail() {
  signUpFormValidationFailure = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function sandboxProjecFormValidationFail() {
  sandboxProjectFormValidationFailure = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function loginFormValidationFail() {
  loginFormValidationFailure = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function featureCategoryClickHandler(featureCategory) {
  featureCategoryClicked = true
  featureCategoryValue = featureCategory.toLowerCase()
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function displayLoginModalClickHandler() {
  displayLoginModalClicked = true
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData()
}

export function cookieSettingsChanged(cookies, cookiesRouter) {
  cookieSettingsClicked = true
  if (cookies) {
    cookiesValue = cookies
  }
  publishEvent = CLICK_PUBLISHING_EVENT

  return generateAndReportData(cookiesRouter)
}

export function reportSearch(isProductId?: boolean) {
  if (isProductId) {
    searchFormFilterClicked = true
    publishEvent = CLICK_PUBLISHING_EVENT
  }

  return generateAndReportData()
}

export function getCdnScripts(params: {}) {
  utagScript = devScript

  if (params.env && params.env.toLowerCase() === PROD_ENV) {
    utagScript = prodScript
  }

  // Check if the analyticsEnabled property has been passed in. If not,
  // this is an error page. 
  if (!params.hasOwnProperty('analyticsEnabled')){
    // setting this to false until we can fix to check if analytics is enabled
    // so error pages do not load the perfomance analytics script
    // The analyticsEnabled property is passed when Tealium is loaded on non-error pages
    params.analyticsEnabled = false

  } 

  if(params && params.analyticsEnabled === false){
    //do not load script if user hasn't opted into analytics
    return (<></>)

  } else {
    // load scripts if user has accepted Analytics/Performance cookies
    return (
      <>
        <Script
          id={jQueryScriptId}
          src="https://code.jquery.com/jquery-3.6.4.min.js"
          integrity="sha256-oP6HI9z1XaZNBrJURtCoUT5SUnxFr8s3BzRl+cbzUq8="
          crossOrigin="anonymous"
        ></Script>
        <Script
          id={uTagsScriptId}
          src={utagScript}
          type="text/javascript"
        ></Script>
      </>
    )
  }

}

export function attachEventHandlers() {
  if (!router) {
    return
  }
  attachContactFormEventHandlers()
  attachSignUpFormEventHandlers()
  attachLoginFormEventHandlers()
  attachMarkdownContentEventHandlers()
}

const Tealium = (params: tealiumData) => {
  status = useGetUserInfoClientSide().status
  userInfo = useGetUserInfoClientSide().userInfo
  router = useRouter()
  
  function routeChangeCallback() {
    analyticsReported = false

    resetUserActivity()
    attachEventHandlers()
    generateReportingData()

    return callReportScript()
  }

  if (!params?.env || params.notFound) {
    return <></>
  }

  if (params && params.analyticsEnabled) {
    
    if (status === LOADING_STATUS) {
      // load required libraries while user status is fetched
      return getCdnScripts(params)
    }
  } else {
    //clear out analytics cookies if user hasn't accepted
    if (typeof window !== 'undefined') {
      let cookies = getCookies()
      cleanUp(cookies)
    }
  }

  if (router.asPath.includes(SupportPageUtility.CREATE_PATH)) {
    supportPage = true
  }

  if (analyticsReported) {
    return <></>
  }


  if (params.analyticsEnabled) {
    if (status === LOADING_STATUS) {
      // load required libraries while user status is fetched
      return getCdnScripts(params)
    }
  } else {
    if (typeof window !== 'undefined') {
      let cookies = getCookies()
      cleanUp(cookies)
    }
    return <></>
  }


  generateReportingData()
  router.events.on('routeChangeComplete', routeChangeCallback)
  attachEventHandlers()

  return callReportScript()
}

export default Tealium
